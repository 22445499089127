import {createTransaction , getTransactions, confirmTransaction, getProductsSold ,addUserFeedback} from "./firebaseConn"
import { Timestamp } from 'firebase/firestore'
import { getUserData } from "../authUtils";




export const createNewOrder = (
    {
        payment_id = 1,
        delivery_id = 1,
        address = "",
        notes = "",
        discount = 0,
      },
      cart,
      token,
  ) => {

    

    let payment_method;
    switch (parseInt(payment_id)) {
        case 1:
            payment_method = "UPI";
            break;
        case 2:
            payment_method = "Card";
            break;
        case 3:
            payment_method = "Cash";
            break;

        default:
            payment_method= "Cash";
            break;
    }

    
        

    const bodyForm = {}
   

    bodyForm.payment_method =  payment_method;
    bodyForm.delivery_id =  delivery_id;
    bodyForm.address =  address;
    bodyForm.notes =  notes;
    bodyForm.discount = discount

    

    bodyForm.orders =  {};

    
    

    let totalPrice = 0
    for (let i = 0; i < cart.length; i++) {
        var order = cart[i]
        bodyForm.orders[i.toString()] =  order;
       
        totalPrice = totalPrice + order["subtotal"]
      }
    
    bodyForm.createdBy = getUserData().email
    bodyForm.created_date =  Timestamp.fromDate(new Date());
    bodyForm.updated_date =  Timestamp.fromDate(new Date());

    bodyForm.total = totalPrice 
    bodyForm.grand_total = totalPrice - discount
    bodyForm.order_status = 1
  
   
   return createTransaction(token,bodyForm,cart);
  };


  export const getAllTransactions = (token
  ) => {
    
    return getTransactions(token)
  };



export const setTransactionDone = (transactionId, token) =>{
    return confirmTransaction(transactionId,token)
}

export const getSellingReport = (view,token) =>{
    return getProductsSold();
}


export const noteUserFeedback = (feedback) => {
    return addUserFeedback(feedback)
}