import React, { useCallback, useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";

import closeIcon from "../../assets/icons/close.svg";
import loadingImage from "../../assets/images/loading.svg";
import productPlaceholder from "../../assets/images/placeholder-promo.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import DeletePromo from "../../components/Promo/DeletePromo";
import PromoNotFound from "../../components/Promo/PromoNotFound";
import {getPromoById, editPromoEntry} from "../../utils/dataProvider/fb_promos"
import useDocumentTitle from "../../utils/documentTitle";
import { n_f } from "../../utils/helpers";

const EditPromo = (props) => {
  const { promoId } = useParams();
  useDocumentTitle("Edit Promo");
  const initialState = {
    name: "",
    desc: "",
    image: "",
  };
  
  const [notFound, setNotFound] = useState(false);
  const [data, setData] = useState({ img: "" });
  const [form, setForm] = useState({
    
    name: "",
    desc: "",
    image: "",
    coupon_code: "",
    start_date: "", // real form
    end_date: "", // real form
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState({
    name: "",
    desc: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [preview, setPreview] = useState("");
  const [cancel, setCancel] = useState(false);
  const [loadings, setLoadings] = useState({
    search: false,
    data: false,
  });

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!form.image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(form.image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [form.image]);

  

  useEffect(() => {
    setLoadings({ ...loadings, data: true });
    getPromoById(promoId)
      .then((result) => {
        const {
          start_date,
          end_date,
        } = result.data;
        // console.log(result.data.data[0]);
        setForm({
          ...form,
          ...result.data,
          startDate: start_date,
          endDate: end_date,
        });
       
        setData({ ...data, img: result.data.img });
        setLoadings({ ...loadings, data: false });
      })
      .catch((err) => {
        setLoadings({ ...loadings, data: false });
        setNotFound(true);
        console.log(err);
      })
      .finally(() => {
        // setLoadings({ ...loadings, data: false });
      });
  }, []);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setForm({ ...form, image: "" });
      return;
    }

    if (e.target.files[0].size > 2097152) {
      return toast.error("Files must not exceed 2 MB");
    }

    // I've kept this example simple by using the first image instead of multiple
    setForm({ ...form, image: e.target.files[0] });
  };

  const [isLoading, setLoading] = useState("");
  const controller = useMemo(() => new AbortController(), []);
  const formChangeHandler = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      form.coupon_code === "" ||
      form.desc === "" ||
      form.name === "" ||
      form.startDate === "" ||
      form.endDate === ""
    ) {
      return toast.error("Input required form");
    }
    if (form.name.length < 6) return toast.error("Promo title min 6 char");
    if (form.desc.length < 10)
      return toast.error("Promo description min 10 char");
    if (form.coupon_code.length < 4)
      return toast.error("Promo coupon code min 6 char");
   

    setLoading(true);
    editPromoEntry(promoId, form, props.userInfo.token)
      .then((result) => {
        navigate(`/menu/`);
        toast.success("Promo edited successfully");
      })
      .catch((err) => {
        if (err.message) {
          toast.error(err.message);
          return;
        }
        toast.error("Error occured while updating promo");
      })
      .finally(() => setLoading(false));
  };

  

  return (
    <>
      <Modal isOpen={cancel} onClose={() => setCancel(!cancel)}>
        <p>Are you sure want to reset the form?</p>
        <section className="flex justify-center gap-x-5 mt-5">
          <button
            className="btn btn-error"
            onClick={() => {
              setForm({ ...initialState });
              setCancel(false);
            }}
          >
            Yes
          </button>
          <button className="btn" onClick={() => setCancel(!cancel)}>
            No
          </button>
        </section>
      </Modal>
      <DeletePromo
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        promoId={promoId}
      />
      <Header />
      {loadings.data ? (
        <Loading />
      ) : notFound ? (
        <PromoNotFound />
      ) : (
        <main className="global-px py-6">
          <nav className="flex flex-row list-none gap-1">
            <li className="after:content-['>'] after:font-semibold text-primary">
              <NavLink to="/menu">Menu </NavLink>
            </li>
            <li className="text-tertiary font-semibold">Edit promo</li>
          </nav>
          <section className="flex flex-col md:flex-row py-14">
            <section className="flex-1 flex flex-col items-center gap-4">
              <div className="avatar">
                <div className="w-52 rounded-full border">
                  <img
                    src={preview || data.img || productPlaceholder}
                    className="object-contain"
                  />
                </div>
              </div>
              <label
                htmlFor="form_image"
                className="btn btn-block btn-lg normal-case mt-2 btn-accent text-white"
              >
                Take a picture
              </label>
              <label
                htmlFor="form_image"
                className="btn btn-block btn-lg normal-case btn-secondary text-tertiary"
              >
                Choose from gallery
              </label>
              <button
                onClick={() => setDeleteModal(true)}
                className="btn btn-block btn-error btn-lg normal-case btn-secondary"
              >
                Delete promo
              </button>
            </section>
            <form
              onSubmit={submitHandler}
              className="flex-[2_2_0%] md:pl-12 lg:pl-24 flex flex-col gap-4"
            >
              <input
                id="form_image"
                type="file"
                accept="image/png, image/webp, image/jpeg"
                className="hidden"
                onChange={onSelectFile}
              />
              
              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="product_name"
              >
                Title :
              </label>
              <input
                placeholder="Type promo title max. 50 characters"
                name="name"
                id="product_name"
                value={form.name}
                onChange={formChangeHandler}
                maxLength={50}
                required
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
              ></input>

              

              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="product_desc"
              >
                Description :
              </label>
              <textarea
                placeholder="Describe your promo min. 10 characters"
                name="desc"
                id="product_price"
                value={form.desc}
                onChange={formChangeHandler}
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
                minLength={10}
                maxLength={50}
                required
              >
                {form.desc}
              </textarea>

              {/* couponcode */}
              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="coupon_code"
              >
                Coupon Code :
              </label>
              <input
                placeholder="Type promo coupon code 6-12 characters"
                name="coupon_code"
                id="coupon_code"
                value={form.coupon_code.toUpperCase()}
                onChange={formChangeHandler}
                maxLength={12}
                required
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
              ></input>

              {/* valid promo date */}
              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="coupon_code"
              >
                Promo date (Start - End) :
              </label>
              <Datepicker
                // containerClassName={"bg-white"}
                inputClassName={
                  "bg-white border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none w-full"
                }
                minDate={new Date()}
                value={form}
                popoverDirection="up"
                separator="until"
                onChange={(e) =>
                  setForm({
                    ...form,
                    startDate: e.startDate,
                    endDate: e.endDate,
                    start_date: e.startDate,
                    end_date: e.endDate,
                  })
                }
              />

              <button
                type="submit"
                className={`${
                  isLoading && "loading"
                } btn btn-block btn-lg normal-case mt-2 btn-primary text-white shadow-lg rounded-2xl`}
              >
                Save Promo
              </button>
              {/* <button
              type="reset"
              onClick={() => setCancel(true)}
              className="btn btn-lg normal-case bg-gray-200 hover:bg-gray-300 border-gray-300 text-tertiary shadow-lg rounded-2xl"
            >
              Reset
            </button> */}
            </form>
          </section>
        </main>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditPromo);
