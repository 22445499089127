import React, { useEffect, useMemo, useState } from "react";

import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { getCategories } from "../../utils/dataProvider/fb_promos";

import productPlaceholder from "../../assets/images/placeholder-image.webp";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import useDocumentTitle from "../../utils/documentTitle";

import {createMenuEntry} from "../../utils/dataProvider/fb_products"

export const NewProduct = (props) => {
  useDocumentTitle("New Menu Item");
  const initialState = {
    name: "",
    price: "",
    priority: "",
    category_id: "",
    image: "",
  };
  const [form, setForm] = useState({
    name: "",
    price: "",
    priority: 10,
    category_id: "",
    image: "",
  });
  const [error, setError] = useState({
    name: "",
    price: "",
    priority: "",
    category_id: "",
  });
  const navigate = useNavigate();
  const [preview, setPreview] = useState("");
  const [cancel, setCancel] = useState(false);

  const [categories, setCategories] = useState([]);
  const [categorySizes, setCategorySizes] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);

  const [sizeForm, setSizeForm] = useState({});

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!form.image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(form.image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [form.image]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setForm({ ...form, image: "" });
      return;
    }

    if (e.target.files[0].size > 2097152) {
      return toast.error("Files must not exceed 2 MB");
      
    }

    // I've kept this example simple by using the first image instead of multiple
    setForm({ ...form, image: e.target.files[0] });
  };

  const fetchCategory = async () => {
    try {
     
      const result = await getCategories();
      setCategories(result.data);
     
    } catch (error) {
     
      setCategories([]);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    
    const filtered_category = categories.filter((cat) =>{
        if (cat._id == form.category_id){
          return true
        }
        else{
          return false
        }
    })
   
    if(filtered_category.length > 0 && filtered_category[0]['sizes']){
      const selectedSizes = Array()
      const sizeFormObj = {}
      for(let i= 0;i< filtered_category[0]['sizes'].length; i++){
        const obj = {}
        const sname = filtered_category[0]['sizes'][i]["name"]
        obj["size_name"] = sname
       
        sizeFormObj[sname] = 0

        selectedSizes.push(obj)
      }
      
      setCategorySizes(selectedSizes)
      setSizeForm(sizeFormObj)
    }
    else{
      setCategorySizes([])
      setSizeForm({})
    }
   

  }, [form.category_id]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const item = event.target.value;

    if (isChecked) {
      setCheckedItems([...checkedItems, item]);
    } else {
      setCheckedItems(checkedItems.filter((i) => i !== item));
    }
  };

  const [isLoading, setLoading] = useState("");
  const controller = useMemo(() => new AbortController(), []);
  const formChangeHandler = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const sizeFormChangeHandler = (e) =>
    setSizeForm({ ...sizeForm, [e.target.name]: e.target.value });

    

  const submitHandler = (e) => {
    e.preventDefault();

    

    if (
      form.category_id === "" ||
      form.name === "" ||
      form.price === ""
    ) {
      return toast.error("Input required form");
    }

    const size_list = {}
    let k = 0
    
    for(let i =0;i<checkedItems.length;i++){
        if (sizeForm[checkedItems[i]] != 0){
          
          const obj = {}
          obj["size_name"] = checkedItems[i]
          obj["size_price"] = parseInt(sizeForm[checkedItems[i]])
          size_list[k] = obj
          k = k + 1
          
        }
    }

    form.sizes = size_list

    
    
    
      setLoading(true);
    createMenuEntry(form, props.userInfo.token)
      .then((result) => {
        navigate(`/menu`);
        toast.success("Item added successfully.");
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
    
   
  };
  return (
    <>
      <Modal isOpen={cancel} onClose={() => setCancel(!cancel)}>
        <p>Are you sure want to reset the form?</p>
        <section className="flex justify-center gap-x-5 mt-5">
          <button
            className="btn btn-error"
            onClick={() => {
              setForm({ ...initialState });
              setCancel(false);
            }}
          >
            Yes
          </button>
          <button className="btn" onClick={() => setCancel(!cancel)}>
            No
          </button>
        </section>
      </Modal>
      <Header />
      <main className="global-px py-6">
        <nav className="flex flex-row list-none gap-1">
          <li className="after:content-['>'] after:font-semibold text-primary">
            <NavLink to="/menu">Menu </NavLink>
          </li>
          <li className="text-tertiary font-semibold">Add new menu item</li>
        </nav>
        <section className="flex flex-col md:flex-row py-14">
          <section className="flex-1 flex flex-col items-center gap-4">
            <div className="avatar">
              <div className="w-52 rounded-full">
                <img src={preview || productPlaceholder} />
              </div>
            </div>
            <label
              htmlFor="form_image"
              className="btn btn-block btn-lg normal-case mt-2 btn-accent text-white"
            >
              Take a picture
            </label>
            <label
              htmlFor="form_image"
              className="btn btn-block btn-lg normal-case btn-secondary text-tertiary"
            >
              Choose from gallery
            </label>
          </section>
          <form
            onSubmit={submitHandler}
            className="flex-[2_2_0%] md:pl-12 lg:pl-24 flex flex-col gap-4"
            
          >
            <input
              id="form_image"
              type="file"
              accept="image/png, image/webp, image/jpeg"
              onChange={onSelectFile}
              className="hidden"
              required 
              
            />
           
            <label
              className="text-tertiary font-bold text-lg"
              htmlFor="product_name"
            >
              Name :
            </label>
            <input
              placeholder="Type item name max. 50 characters"
              name="name"
              id="product_name"
              value={form.name}
              onChange={formChangeHandler}
              maxLength={50}
              required
              className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
            ></input>

           
            <label
              className="text-tertiary font-bold text-lg"
              htmlFor="product_price"
            >
              Base Price :
            </label>
            <input
              placeholder="Type the price"
              name="price"
              type="number"
              id="product_price"
              value={form.price}
              onChange={formChangeHandler}
              className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
            />



            <label
              className="text-tertiary font-bold text-lg"
              htmlFor="product_priority"
            >
              Item priority :
            </label>
            <input
              placeholder="Item priority (1 to 10)."
              name="priority"
              type="number"
              id="product_priority"
              value={form.priority}
              onChange={formChangeHandler}
              className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
            />

          

           

            <label
              className="text-tertiary font-bold text-lg"
              htmlFor="product_category"
            >
              Category :
            </label>
            <select
              name="category_id"
              id="form_category"
              value={form.category_id}
              onChange={formChangeHandler}
              className="select select-bordered w-full rounded-xl"
            >
              <option disabled value="">
                Select related category
              </option>
              {categories.map((category, idx) => (
                <option key={idx} value={`${category._id}`}>{category.category_name}</option>
              )
              )}
              
            </select>
           { categorySizes.length > 0 &&
          <table className="table-fixed">
            <thead>
              <tr>
                <th>Select</th>
                <th>Size Name</th>
                <th>Price</th>
              </tr>
            </thead>
          
            <tbody>
            {categorySizes.map((option,idx) => (
              <tr key={idx}>
                <td>
                <input
                  type="checkbox"
                  value={option.size_name}
                  checked={checkedItems.includes(option.size_name)}
                  onChange={handleCheckboxChange}
                />
                </td>
                <td>
              <label key={idx}
              className="text-tertiary font-bold text-lg"
              >
                {option.size_name}  
              </label>
              </td>
              <td>
              <input
                  placeholder="Price"
                  name={option.size_name}
                  type="number"
                  id="size_price"
                  value={sizeForm[option.size_name]}
                  onChange={sizeFormChangeHandler}
                  className="flex-1 border-b-2 text-center py-2 border-gray-300 focus:border-tertiary outline-none"
                />
              </td>
              </tr>
            ))}
            </tbody>
            </table>
            }


            <button
              type="submit"
              onClick={submitHandler}
              className={`${
                isLoading && "loading"
              } btn btn-block btn-lg normal-case mt-2 btn-primary text-white shadow-lg rounded-2xl`}
            >
              Save menu item
            </button>
            <button
              type="reset"
              onClick={() => setCancel(true)}
              className="btn btn-lg normal-case bg-gray-200 hover:bg-gray-300 border-gray-300 text-tertiary shadow-lg rounded-2xl"
            >
              Reset
            </button>
          </form>
        </section>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct);
