import React, {
 
  Component,
  Fragment,
} from 'react';

import { useEffect, useState } from "react";

import { Link } from 'react-router-dom';

import { toast } from "react-hot-toast";

// assets icons
import checkCircle from '../assets/icons/check-circle.svg';
import checkIcon from '../assets/icons/check.svg';
import loveIcon from '../assets/icons/love.svg';
import placeIcon from '../assets/icons/place.svg';
import starIcon from '../assets/icons/star.svg';
import staffIcon from '../assets/icons/user.svg';
import mapImage from '../assets/images/global.svg';
import amazonLogo from '../assets/images/partners/amazon.svg';
import discordLogo from '../assets/images/partners/discord.svg';
import netflixLogo from '../assets/images/partners/netflix.svg';
import redditLogo from '../assets/images/partners/reddit.svg';
import spotifyLogo from '../assets/images/partners/spotify.svg';
import phProfile from '../assets/images/placeholder-profile.jpg';
import productImage1 from '../assets/images/product-1.webp';
// assets images
import provideImage2 from '../assets/images/team-work.webp';

import mapLocImage from '../assets/images/location_map.jpg';

import provideImage from '../assets/images/chai_benefits.jpg';

import shopIllu from '../assets/images/shopIllu.png';
// components
import Footer from '../components/Footer';
import Header from '../components/Header';

import {noteUserFeedback} from "../utils/dataProvider/fb_orders"
import {getAccount} from "../utils/dataProvider/fb_account"



class Mainpage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      feedback: {
        customer_name: "",
        customer_mob:"",
        customer_msg: "",
        msg_date: new Date()
      },
      date: new Date(),
     
      main_items: [
        {
          name:"Chai varieties",
          img:"https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/main%2Fchai_combo.jpg?alt=media&token=6700ba4b-cb62-4bbc-b198-ead21b69e12e"
        },
        {
          name:"Momos varieties",
          img:"https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/main%2Fmomo_varieties.jpg?alt=media&token=df4eacc1-ecb9-4930-91a5-c1e55b260147"
        },
        {
          name:"Jucies and Mojitos",
          img:"https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/main%2Fmojitos_varieties.jpg?alt=media&token=fc41d37e-ba29-4565-bb49-4d062883e8b2"
        }
      ],
      reviews: [
        {
          name: "Foo Barr",
          text: "Wow... I am very happy to spend my whole day here. the Wi-fi is good, and the coffee and meals tho. I like it here!! Very recommended!",
        },
        {
          name: "Yessica Christy",
          text: "I like it because I like to travel far and still can make my day better just by drinking their Hazelnut Latte",
        },
        {
          name: "Kim Young Jou",
          text: "This is very unusual for my taste, I haven’t liked coffee before but their coffee is the best! and yup, you have to order the chicken wings, the best in town",
        },
      ],
    };
  }


  
  sendWhatsAppMessage = () =>{
    getAccount()
    .then((result) => {
    
      if(result.data != undefined){
        if(result.data.owner_whatsapp){
          // console.log(this.state.feedback)
          var message = `Message from ${this.state.feedback.customer_name}, ${this.state.feedback.customer_msg}`;
          var encodedMessage = encodeURIComponent(message);
                
         var url = `https://api.whatsapp.com/send?phone=${result.data.owner_whatsapp}&text=`;
                
          var final_url = url.concat(encodedMessage);

            this.setState((prevState) => ({
              feedback: {
                ...prevState.feedback,
                customer_name: "",
                customer_mob:"",
                customer_msg:"",
                sent_whatsapp : false,
                msg_date: new Date()
              },
            }));
          window.location.href = final_url;
          
        }
        
        
      }
      else{
        return toast.error("Fail to connect to Whatsapp");
      }
      
      
    })
    .catch((err) => {
      
      console.log(err);
      
    })

    
  }
 

  formChangeHandler = (event) => {

    if(event.target.name != "sent_whatsapp" ){
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          [event.target.name]: event.target.value,
        },
      }));
    }
    else{
      if(event.target.checked){
        this.setState((prevState) => ({
          feedback: {
            ...prevState.feedback,
            [event.target.name]: event.target.checked,
          },
        }));
      }
      else{
        this.setState((prevState) => ({
          feedback: {
            ...prevState.feedback,
            [event.target.name]: false,
          },
        }));
      }
      
    }
    
    
  };
  

  submitHandler = (e) => {
    e.preventDefault();

    // console.log(this.state.feedback)
    

    if (
      this.state.feedback.customer_name === "" ||
      this.state.feedback.customer_mob === "" ||
      this.state.feedback.customer_desc === ""
    ) {
      return toast.error("Input required form");
    }


  
    noteUserFeedback(this.state.feedback)
      .then((result) => {
        toast.success(result.msg);
        
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() =>{
        if(this.state.feedback.sent_whatsapp)
        {
          this.sendWhatsAppMessage()
          
        }
        else{
          this.setState((prevState) => ({
            feedback: {
              ...prevState.feedback,
              customer_name: "",
              customer_mob:"",
              customer_msg:"",
              sent_whatsapp : false,
              msg_date: new Date()
            },
          }));
          
        }

        

      } );


    
   
  };

  

  render() {
    return (
      <Fragment>
        <Header />
        <main>
          <section className="bg-main bg-cover bg-center py-20 text-white font-bold">
            <div className="global-px">
              <div className="flex flex-col gap-6 w-[75%] lg:w-[50%] text-sm">
                <h2 className="text-4xl font-bold">
                  Is there any feeling better than having a hot cup of Chai?
                </h2>
                <p>
                  If you have a special love for Chai, we at chaiguru welcomes you.
                </p>
                <div className="mt-5">
                  <Link
                    className="bg-secondary px-6 py-4 text-[#6A4029] rounded-xl"
                    to={"/menu/"}
                  >
                    See our Menu
                  </Link>
                </div>
              </div>
              
              <section className="relative bg-white mt-20 mb-[-9rem] rounded-xl shadow-xl text-quartenary flex flex-row py-5 justify-center items-center text-center md:text-left">
                {/* <aside className="flex-1 border-r-2 py-2 md:py-6 flex flex-col md:flex-row justify-center gap-3 md:gap-8 items-center">
                  <div>
                    <div className="bg-secondary rounded-full p-2 w-10 aspect-square flex justify-center items-center">
                      <img src={loveIcon} alt="" />
                    </div>
                  </div>
                  <div>
                    <p className="text-md lg:text-xl"></p>
                    <p className="font-normal text-primary">Chai & snacks</p>
                  </div>
                </aside> */}
                <aside className="flex-1 border-r-2 py-2 md:py-6 flex flex-col md:flex-row justify-center gap-3 md:gap-8 items-center">
                  <div className=" rounded-full p-2 w-22 aspect-square flex justify-center items-center">
                    <img src={shopIllu} alt="" />
                  </div>
                  
                </aside>
                <aside className="flex-1 py-2 md:py-6 flex flex-col md:flex-row justify-center gap-3 md:gap-8 items-center">
                  <div className="bg-secondary rounded-full p-2 w-10 aspect-square flex justify-center items-center">
                    <img src={placeIcon} alt="" />
                  </div>
                  <div>
                    <p className="text-md lg:text-xl"></p>
                    <p className="font-normal text-primary">Koothattukulam</p>
                  </div>
                </aside>
              </section>
            </div>
          </section>
          <div className="mb-8 md:mb-20"></div>
          
          
          <section className="global-px py-8 md:py-20 mt-10">

          <div className="flex flex-col items-center">
              <h2 className="text-4xl text-quartenary font-semibold mb-5 mt-5 text-center">
                Here is our special menu
              </h2>
              <p className="text-base text-gray-700 text-center">
                Let’s choose and have a bit taste of people’s favorite. It might
                be yours too!
              </p>
            </div>

            <div className="flex flex-col items-center mt-5">
            <div className="carousel rounded-box">
            <div className="carousel-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/slider%2Fchai.jpg?alt=media&token=a801f702-701c-49a0-ba36-71f413655665" alt="Chai" />
            </div> 
            <div className="carousel-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/slider%2Fbubble_tea.jpg?alt=media&token=6dd32230-ec0c-4396-a7de-b73d5b9f2b08" alt="Bubble Tea" />
            </div> 
            <div className="carousel-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/slider%2Fmomos.jpg?alt=media&token=29c9f7c2-575a-47cc-a02c-bcf4cbe481f8" alt="Momos" />
            </div> 
            <div className="carousel-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/slider%2Fshake.jpg?alt=media&token=5e00c819-b901-4901-b55c-af2b8f25c505" alt="Shakes" />
            </div> 
            <div className="carousel-item">
              <img src="https://firebasestorage.googleapis.com/v0/b/chai-guru.appspot.com/o/slider%2Fmojitos.jpg?alt=media&token=f1c6675b-7961-44fd-80de-f326bf26558a" alt="Mojitos" />
            </div> 
            
          </div>
            </div>
          </section>
          {/* <section className="global-px py-8 md:py-20 mt-10">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl text-quartenary font-semibold mb-5 mt-5 text-center">
                Here is our special menu
              </h2>
              <p className="text-base text-gray-700 text-center">
                Let’s choose and have a bit taste of poeple’s favorite. It might
                be yours too!
              </p>
            </div>
            <div className="flex flex-row flex-wrap justify-center gap-12 mt-20">
              {this.state.main_items.map((item, idx) => (
                <div
                  className="flex-1 flex flex-col justify-center border-gray-400 border rounded-xl px-5 py-5 md:py-12 items-center gap-5 text-base"
                  key={idx}
                >
                  <img
                    src={item.img}
                    alt=""
                    width="140px"
                    className="rounded-full mb-7"
                  />
                  <h3 className="text-lg font-medium">{item.name}</h3>
                  
                  
                </div>
              ))}
            </div>
          </section> */}
          <section className="global-px py-8 md:py-20">
            <div className="flex flex-col items-center mb-8 md:mb-20">
              <h2 className="text-4xl text-quartenary font-semibold  text-center">
                Visit Our Store near
                <br />
                
              </h2>
              <p className='text-base text-center mt-1'> 
              South India Bank, Kottayam road , Koothattukulam , Kerala
              </p>
              <p className="text-sm text-gray-700 text-center">
                We open 2 PM to 2 AM.
              </p>
            </div>
            <div className="mt-10">
            <div className='overflow-hidden relative h-0 pb-[58%] mb-2'>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194.18002703683186!2d76.59335225617116!3d9.854904607773376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07da55347046a9%3A0xd629dcc9ed078c43!2sSBI%20ATM!5e1!3m2!1sen!2sin!4v1707755372100!5m2!1sen!2sin"
              width="800"
              height="600"
              className='top-0 left-0 border-0 h-full w-full absolute'
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">
                                  </iframe>
              </div>

              
                              
              {/* <img src={mapLocImage} alt="global map" /> */}
            </div>
            <div className="flex flex-col items-center mb-2 md:mb-2">
              
                <button 
                className="ml-auto w-[100%]   bg-primary rounded-xl py-4 text-white font-bold"
                onClick={() => window.location.href ='https://maps.app.goo.gl/EGMbqw341weaLjTp9'}
                >
                Locate us
                </button>
              
            </div>
          </section>
          <section className="flex flex-col lg:flex-row global-px py-20 lg:gap-32">
            <div className="flex-1 img">
              <img src={provideImage} alt="" width="100%" />
            </div>
            <div className="flex-1 flex flex-col justify-center gap-5">
              <h2 className="text-quartenary font-semibold text-[35px]">
              Contact us
              </h2>

              <form
              onSubmit={this.submitHandler}
              className="flex-[2_2_0%]  flex flex-col gap-4"
            >
              
              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="customer_name"
              >
                Your name :
              </label>
              <input
                placeholder="Type your name."
                name="customer_name"
                id="customer_name"
                value={this.state.feedback.customer_name}
                onChange={this.formChangeHandler}
                maxLength={50}
                required
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
              ></input>

            <label
                className="text-tertiary font-bold text-lg"
                htmlFor="customer_number"
              >
                Mobile number :
              </label>
              <input
                placeholder="Type your mobile number"
                name="customer_mob"
                id="customer_number"
                type="number"
                value={this.state.feedback.customer_mob}
                onChange={this.formChangeHandler}
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
              />


              

              <label
                className="text-tertiary font-bold text-lg"
                htmlFor="customer_msg"
              >
                Message :
              </label>
              <textarea
                placeholder="Write your feedback and suggestions."
                name="customer_msg"
                id="customer_msg"
                value={this.state.feedback.customer_msg}
                onChange={this.formChangeHandler}
                className="border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none"
                minLength={10}
                maxLength={50}
                required
              >
               
              </textarea>

             

            <div className="form-control">
              <label 
              className="cursor-pointer label"
              htmlFor="sent_whatsapp">
                <span className="label-text">Send whatsapp message</span>
                <input 
                type="checkbox"  
                name="sent_whatsapp"
                id="sent_whatsapp"
                onChange={this.formChangeHandler}
                className="checkbox checkbox-success" />
              </label>
            </div>

              <button
                type="submit"
                onClick={this.submitHandler}
                // disabled={disabled}
                className="btn btn-block btn-lg normal-case mt-2 btn-primary text-white shadow-lg rounded-2xl disabled:text-gray-400"
              >
                Save your feedback
              </button>
              </form>
              
              
            </div>
          </section>
          {/*
          <section className="global-px py-8 md:py-20">
            <div className="flex flex-col items-center mb-8 md:mb-20">
              <h2 className="text-4xl text-quartenary font-semibold mb-5 text-center">
                Our Partner
              </h2>
            </div>
            <div className="flex flex-row flex-wrap justify-center items-center gap-4 md:gap-12 ">
              <img
                src={netflixLogo}
                alt=""
                width="100px"
                className="lg:w-[15%] aspect-[3/2] object-contain grayscale opacity-20 duration-300 hover:filter-none hover:opacity-100"
              />
              <img
                src={redditLogo}
                alt=""
                width="100px"
                className="lg:w-[15%] aspect-[3/2] object-contain grayscale opacity-20 duration-300 hover:filter-none hover:opacity-100"
              />
              
            </div>
          </section>
              */}

          {/*

          <section className="global-px py-8 md:py-20">
            <div className="flex flex-col items-center mb-8 md:mb-20 text-center">
              <h2 className="text-3xl md:text-[35px] text-quartenary font-semibold mb-5">
                Loved by Customer of
                <br /> Happy Customer
              </h2>
              <p className="text-[1rem] text-center max-w-[555px] text-primary">
                These are the stories of our customers who have visited us with
                great pleasure.
              </p>
            </div>
            <div className="overflow-auto flex flex-row gap-5 flex-wrap lg:flex-nowrap ">
              {this.state.reviews.map((review, idx) => {
                return (
                  <div
                    className="w-[400px] border-gray-300 hover:border-tertiary border-2 duration-200 rounded-xl p-7 space-y-4 hover:shadow-2xl mx-auto"
                    key={idx}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <img
                        src={phProfile}
                        alt=""
                        className="w-14 aspect-square object-cover rounded-full"
                      />
                      <div className="flex-1">
                        <p className="font-semibold text-quartenary text-lg">
                          {review.name}
                        </p>
                        <p className="text-primary text-sm">Warsaw, Poland</p>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        4.5 <img src={starIcon} alt="" />
                      </div>
                    </div>
                    <p className="text-quartenary">“{review.text}</p>
                  </div>
                );
              })}
            </div>
          </section>

          */}

          <section className="global-px z-10 relative w-full mb-6 md:mb-[-6rem]">
            <div className="shadow-primary rounded-xl flex flex-col md:flex-row py-10 md:py-14 px-8 md:px-16 bg-white text-center md:text-left">
              <aside className="flex-1 space-y-4 mb-5 md:mb-0">
                <p className="text-3xl font-semibold">Check our promo today!</p>
                <p className="text-primary">
                  Let&apos;s see the deals and pick yours
                </p>
              </aside>
              <aside className="hidden lg:block lg:flex-1"></aside>
              <aside className="flex-1 flex flex-col justify-center">
              <a
                  href="/menu"
                >
                <button className="ml-auto w-[100%] md:w-[75%]  bg-secondary rounded-xl py-4 text-tertiary font-bold">
                  See promo and menu
                </button>
              </a>
              </aside>
            </div>
          </section>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default Mainpage;
