import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import AdminDashboard from "./pages/Admin";
import ManageOrder from "./pages/Admin/ManageOrder";
import Auth from "./pages/Auth";
import ForgotPass from "./pages/Auth/ForgotPass";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPass from "./pages/Auth/ResetPass";
import Cart from "./pages/Cart";
import NotFound from "./pages/Error";
import History from "./pages/History";
import HistoryDetail from "./pages/History/HistoryDetail";
import Mainpage from "./pages/Mainpage";
import Products from "./pages/Products";
import EditProduct from "./pages/Products/EditProduct";
import NewProduct from "./pages/Products/NewProduct";
import ProductDetail from "./pages/Products/ProductDetail";
import Profile from "./pages/Profile";
import EditPromo from "./pages/Promo/EditPromo";
import NewPromo from "./pages/Promo/NewPromo";
import ScrollToTop from "./utils/scrollToTop";
import {
  CheckAuth,
  CheckIsAdmin,
  CheckNoAuth,
  TokenHandler,
} from "./utils/wrappers/protectedRoute";

const reload = () => window.location.reload();

// const AllRouter = createBrowserRouter(createRoutesFromElements());

const Routers = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<TokenHandler />}>
          {/* Public Route */}
        <Route path="*" element={<NotFound />} />

        <Route path="/sitemap.xml" onEnter={reload} />
          
        <Route index element={<Mainpage />} />
          
        <Route path="menu/*" element={<Products title="Menu" />}>
            <Route path="category/:slug" element={""} />
           
        </Route>
         
       
          {/* Route which must not logged in */}
          <Route
            path="auth"
            element={
              <CheckNoAuth>
                <Auth />
              </CheckNoAuth>
            }
          >
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
          
          </Route>

          {/* Route which must logged in */}
          <Route element={<CheckAuth />}>
           {/* <Route path="profile" element={<Profile title="User Profile" />} /> */}
            
          </Route>

          {/* Route which only admin */}
          <Route element={<CheckIsAdmin />}>

          <Route path="billing" element={<Cart />} />
          <Route path="reports" element={<AdminDashboard />} />
          
          
            <Route path="menu/new" element={<NewProduct />} />
            <Route path="manage-order" element={<ManageOrder />} />
            <Route path="menu/edit/:productId" element={<EditProduct />} />
            <Route path="promo/new" element={<NewPromo />} />
            <Route path="promo/edit/:promoId" element={<EditPromo />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
