import { constant } from "lodash";
import { getMenuStatic,getMenuByPaginated,createMenuItem, getFbMenuItembyId, updateMenuItem , deleteFbMenuItembyId ,getItemBySearch} from "./firebaseConn"

export function getMenu(
    catId = "",
    { searchByName = "", limit = "8", page = "1" }
){
    return getMenuByPaginated(catId,
    {  searchByName, limit, page })
}



export function getAllItems(searchItem){
    
    return getItemBySearch(searchItem)
}


export const createMenuEntry = (
    { name = "", price = "",priority= 10, category_id = "", image = "",sizes },
    token
  ) => {
    const bodyForm = {}
   
    bodyForm.name =  name;
    bodyForm.category_id =  category_id;
    bodyForm.price =  price;
    bodyForm.sizes =  sizes;
    bodyForm.priority =  parseInt(priority);
  
    
   return createMenuItem(token,bodyForm,image);
  };

  export const editMenuEntry = (
    { name = "", price = "",priority= 10, category_id = "", image = "" ,sizes},
    productId,
    token
  ) => {
    const bodyForm = {}
   
    bodyForm.name =  name;
    bodyForm.category_id =  category_id;
    bodyForm.price =  price;
    bodyForm.sizes =  sizes;
    bodyForm.priority =  parseInt(priority);
  
    
   return updateMenuItem(productId,token,bodyForm,image);
  };


export const getMenuItembyId = (product_id) => {

    return getFbMenuItembyId(product_id);

}

export const deleteMenuEntry = (productId, token) => {
    if(token.length > 0){
        return deleteFbMenuItembyId(productId);
    }
    
};
  



