import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs ,getDoc,query, where ,doc ,addDoc , updateDoc, orderBy ,deleteDoc ,setDoc  } from 'firebase/firestore';
import { getStorage, ref as storageRef , uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { groupByWeeks,groupByMonths , findDateInGroups} from "../fb_helper";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCxHWR8oUedd9QDV-qyo7JETz4V3mhaIig",
  authDomain: "chai-guru.firebaseapp.com",
  projectId: "chai-guru",
  storageBucket: "chai-guru.appspot.com",
  messagingSenderId: "226264376241",
  appId: "1:226264376241:web:e3317191422e8fde4a5390"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app);

const storage = getStorage(app);

// Get a list of cities from your database
export async function getMenuStatic(
    catId = "",
    { orderBy = "", sort = "", searchByName = "", limit = "8", page = "1" }
) {



  const params = { orderBy, sort, searchByName, limit, page };

  if(!params.page){
    params.page = 1
  }

  const menuCol = collection(db, 'menu');
  

  let menuSnapshot 

  if(catId){
    const q = query(collection(db, "menu"), where("category_id", "==", catId));
   
    menuSnapshot = await getDocs(q);
  }
  else{
    menuSnapshot = await getDocs(menuCol);
  }

  //const categoryRef = doc(db, "category", );
  
  
  const menuList = Array()
  //const menuList = menuSnapshot.docs.map(doc => doc.data());
  menuSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id
    
    menuList.push(data)

    
  });

  

  const numDocs = menuList.length;

  const no_pages = Math.floor(numDocs / params.limit) + 1

  const final_out = {}

  var prev = null

  var next = null

  final_out['status'] = 200
  final_out['msg'] =  "Fetch success"
  final_out['meta'] = {}

  final_out['meta']['totalData'] = numDocs

  if(parseInt(params.page) == 1){
    prev = null
  }
  else{
    prev = "/products?page="+String(page - 1)+"&limit=8"
  }

  final_out['meta']['prev'] = prev

  if(parseInt(params.page) == no_pages){
    next = null
  }
  else{
    next = "/products?page="+String(page + 1)+"&limit=8"
  }


  final_out['meta']['next'] = next

  final_out['meta']['currentPage'] = parseInt(params.page)

  final_out['meta']['totalPage'] = no_pages

  final_out['data'] = menuList


  return final_out;
}

export async function getItemBySearch(searchItem){

  const menuList = Array()

  if(searchItem == ""){
    return menuList
  }
  
  const menuRef = collection(db, "menu");
  const menuSnapshot = await getDocs(menuRef);
  

  menuSnapshot.forEach((doc) => {

    const data = doc.data();
    data["_id"] = doc.id   

    
    if(data["sizes"]){
      const sizes = Object.values(data["sizes"])
      data["sizes"] = sizes
    }
    
    
    menuList.push(data);

    
    
  });


  let result_list = Array()
          
  menuList.map((data)=>{
    
    const obj = {}
    obj["_id"] = data["_id"]
    obj['name'] = data['name']
    obj['price'] = parseFloat(data['price'])
    obj['category_id'] = data['category_id']
    obj['img'] = data['img'] || ""


    result_list.push(JSON.stringify(obj))

    const item_name =data["name"]
    if(data["sizes"]){
      const sizes = data["sizes"]
      for(let i=0;i<sizes.length;i++){
        obj["name"] = item_name + " - " + sizes[i]["size_name"]
        obj["price"] = parseFloat(sizes[i]["size_price"] )
        result_list.push(JSON.stringify(obj));
      }
    }
    
    

  });

  let final_list = Array()
  result_list.map((data)=>{
    final_list.push(JSON.parse(data))
  })




  const filteredMenu = final_list.filter(menu => {
    const searchTerm = searchItem.toLowerCase()
    const item_name = menu.name.toLowerCase()
    return item_name.includes(searchTerm)
  });

  return filteredMenu

}


export async function getMenuByPaginated(
  catId = "",
  { searchByName = "", limit = "8", page = "1" }
) {



const params = { searchByName, limit, page };

if(!params.page){
  params.page = 1
}

const menuRef = collection(db, "menu");


let menuSnapshot 

const q = query(menuRef,orderBy("priority"));
menuSnapshot = await getDocs(q);

// if(catId){
//   const q = query(menuRef, where("category_id", "==", catId),orderBy("category_id"));
//   menuSnapshot = await getDocs(q);
// }
// else{
//   const q = query(menuRef,orderBy("priority"));
//   menuSnapshot = await getDocs(q);
// }




const menuList = Array()

if(catId)
{
  menuSnapshot.forEach((doc) => {

    const data = doc.data();
    data["_id"] = doc.id

    if(data["sizes"]){
      const i_sizes = data["sizes"]
      const size_list = Object.values(i_sizes)
      data["sizes"] = size_list
    }

    if(catId && data["category_id"] == catId){
      menuList.push(data);
    } 
  });
}
else{
  menuSnapshot.forEach((doc) => {

    const data = doc.data();
    data["_id"] = doc.id

    if(data["sizes"]){
      const i_sizes = data["sizes"]
      const size_list = Object.values(i_sizes)
      data["sizes"] = size_list
    }
    
    

    menuList.push(data);
    
  });
}



const numDocs = menuList.length;

const no_pages = Math.floor(numDocs / params.limit) + 1

const final_out = {}

var prev = null

var next = null

final_out['status'] = 200
final_out['msg'] =  "Fetch success"
final_out['meta'] = {}

final_out['meta']['totalData'] = numDocs

if(parseInt(params.page) == 1){
  prev = null
}
else{
  prev = "/products?page="+String(page - 1)+"&limit=8"
}

final_out['meta']['prev'] = prev

if(parseInt(params.page) == no_pages){
  next = null
}
else{
  next = "/products?page="+String(page + 1)+"&limit=8"
}


final_out['meta']['next'] = next

final_out['meta']['currentPage'] = parseInt(params.page)

final_out['meta']['totalPage'] = no_pages

const start_idx = (parseInt(params.page) - 1 )  * params.limit
const end_idx = (parseInt(params.page) * params.limit) 
const paginatedList = menuList.slice(start_idx, end_idx);

final_out['data'] = paginatedList


return final_out;
}


export async function getDiscountStatic(isAdmin = false,
    { page = 1, limit = 4, available = "true"}
) {

    const params = { page, limit, available };

    if(!params.page){
      params.page = 1
    }

    const discountCol = collection(db, 'discounts');
    const discountSnapshot = await getDocs(discountCol);
    const discountList = Array()
    discountSnapshot.forEach((doc) => {

      const data = doc.data();
      data["_id"] = doc.id
  
      discountList.push(data);
      
    });

    //const discountList = discountSnapshot.docs.map(doc => doc.data());


    const numDocs = discountList.length;

    const no_pages = Math.floor(numDocs / params.limit) + 1

    const final_out = {}

    var prev = null

    var next = null

    final_out['status'] = 200
    final_out['msg'] =  "Fetch success"
    final_out['meta'] = {}

    final_out['meta']['totalData'] = numDocs

    if(parseInt(params.page) == 1){
        prev = null
    }
    else{
        prev = "/discounts?page="+String(page - 1)+"&limit=4"+"&available="+String(true)
    }

    final_out['meta']['prev'] = prev

    if(parseInt(params.page) == no_pages){
        next = null
    }
    else{
        next = "/discounts?page="+String(page + 1)+"&limit=4"+"&available="+String(true)
    }


    final_out['meta']['next'] = next

 
    const today = new Date();

   var updatedList

   if(isAdmin){
    updatedList = discountList
   }
   else{

    updatedList = discountList.filter((promo) => {

      var start_date_timestamp = promo.start_date;
      var end_date_timestamp = promo.end_date;

      var start_date = new Date(start_date_timestamp.seconds * 1000 + start_date_timestamp.nanoseconds / 1000000);
      var end_date = new Date(end_date_timestamp.seconds * 1000 + end_date_timestamp.nanoseconds / 1000000);

      var isBetween = today >= start_date && today <= end_date;

      return isBetween;  

  });

   }



    
    final_out['data'] = updatedList 


    return final_out;


}



function checkSize(size_id){

  let sizeName;
  switch (size_id) {
    case 2:
      sizeName = "Large";
      break;
    case 3:
      sizeName = "Xtra Large";
      break;

    default:
      sizeName = "Regular";
      break;
    }
    return sizeName
}


export async function getTransactions(token){

    const final_out = {}

    const orderRef = collection(db, "transactions");
    const q = query(orderRef,orderBy("created_date","desc"));
    const orderSnapshot = await getDocs(q);

    const transactionList = Array()
    orderSnapshot.forEach((doc) => {

      const data = doc.data();
      data["_id"] = doc.id

      const t_orders = data["orders"]
      const orders_list = Object.values(t_orders)
      
      for(let i = 0 ; i < orders_list.length; i++ ){
         orders_list[i]["size"] = orders_list[i]["size_id"]
  
      }
      data["orders"] = orders_list
  
      transactionList.push(data);

    });

      final_out['all_orders'] = transactionList

      var todaysOrders 

      const now = new Date();

      const hours = now.getHours();

      const isBefore1PM = hours < 13;

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 2)

      const today = new Date();
      today.setHours(10, 0, 0, 0);

      

      todaysOrders  = transactionList.filter((order) => {

        var created_date = order.created_date;
  
        var order_date = new Date(created_date.seconds * 1000 + created_date.nanoseconds / 1000000);
        var isBetween 
        if (isBefore1PM){
          isBetween =  order_date <= now && order_date > yesterday;
        }
        else{
          isBetween = order_date >= today
        }
  
        return isBetween;  
  
      });

      final_out['last_orders'] = todaysOrders
      
   
      return final_out

}

export async function getProductsSold(){

    const final_out = {}

    const orderRef = collection(db, "transactions");
    const q = query(orderRef,orderBy("created_date","desc"));
    const orderSnapshot = await getDocs(q);

    const report_init = {}
    const transactionList = Array()
    
    orderSnapshot.forEach((doc) => {
      


      const data = doc.data();
      
      var order_date = new Date(data.created_date.seconds * 1000 + data.created_date.nanoseconds / 1000000)
      const created_date = order_date.getFullYear() + "-" + ('0' + (order_date.getMonth() + 1)).slice(-2) + "-" +  ('0' + (order_date.getDate())).slice(-2)

     
      if(report_init[created_date] == undefined){
        report_init[created_date] = {}
        report_init[created_date]["no_transactions"] = 1
        report_init[created_date]["daily_total"] = data.grand_total
        report_init[created_date]["daily_added_discounts"] = parseInt(data.discount) 

      }
      else{
        report_init[created_date]["no_transactions"] = report_init[created_date]["no_transactions"] + 1
        report_init[created_date]["daily_total"] = report_init[created_date]["daily_total"] + data.grand_total
        report_init[created_date]["daily_added_discounts"] = report_init[created_date]["daily_added_discounts"] + parseInt(data.discount)
      }



      const t_orders = data["orders"]
      const orders_list = Object.values(t_orders)

      if(report_init[created_date]["order_items"] == undefined){
        report_init[created_date]["order_items"] = {}

        for(let i=0;i<orders_list.length;i++){
          const product_name = orders_list[i]['name']
          const item_name = orders_list[i]['name'].split(" - ")[0]
          const size_name = orders_list[i]['name'].split(" - ")[1] || "Regular"

          const product_id = orders_list[i]['product_id']

          


          if(report_init[created_date]["order_items"][product_id] == undefined){
            const p_obj = {}
            p_obj['itemName'] = orders_list[i]['name']
            p_obj['qty'] = orders_list[i]['qty']
            p_obj['total'] = orders_list[i]['qty'] * parseFloat(orders_list[i]['price'])
            p_obj['product_id'] = orders_list[i]['product_id']
            p_obj['img'] = orders_list[i]['img']
            
            report_init[created_date]["order_items"][orders_list[i]['product_id']] = {}
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['product_name'] = item_name
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['img'] = orders_list[i]['img'] || ""
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'] = {}
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] = p_obj
          }
          else{
              if(report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] == undefined){
                const p_obj = {}
                p_obj['itemName'] = orders_list[i]['name']
                p_obj['qty'] = orders_list[i]['qty']
                p_obj['total'] = orders_list[i]['qty'] * parseFloat(orders_list[i]['price'])
                p_obj['product_id'] = orders_list[i]['product_id']
                p_obj['img'] = orders_list[i]['img']
               
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] = p_obj
              }
              else{
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name]['qty'] += parseFloat(orders_list[i]['qty'])
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name]['total'] += parseFloat(orders_list[i]['qty']) * parseFloat(orders_list[i]['price'])
              }
          }
        }
        
      }else{
        for(let i=0;i<orders_list.length;i++){
          const product_name = orders_list[i]['name']
          const item_name = orders_list[i]['name'].split(" - ")[0]
          const size_name = orders_list[i]['name'].split(" - ")[1] || "Regular"



          if(report_init[created_date]["order_items"][orders_list[i]['product_id']] == undefined){
            const p_obj = {}
            p_obj['itemName'] = orders_list[i]['name']
            p_obj['qty'] = orders_list[i]['qty']
            p_obj['total'] = orders_list[i]['qty'] * parseFloat(orders_list[i]['price'])
            p_obj['product_id'] = orders_list[i]['product_id']
            p_obj['img'] = orders_list[i]['img']
            
            report_init[created_date]["order_items"][orders_list[i]['product_id']] = {}
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['product_name'] = item_name
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['img'] = orders_list[i]['img'] || ""
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'] = {}
            report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] = p_obj
          }
          else{
              if(report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] == undefined){
                const p_obj = {}
                p_obj['itemName'] = orders_list[i]['name']
                p_obj['qty'] = orders_list[i]['qty']
                p_obj['total'] = orders_list[i]['qty'] * parseFloat(orders_list[i]['price'])
                p_obj['product_id'] = orders_list[i]['product_id']
                p_obj['img'] = orders_list[i]['img']
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name] = p_obj
              }
              else{
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name]['qty'] += orders_list[i]['qty']
                report_init[created_date]["order_items"][orders_list[i]['product_id']]['items'][size_name]['total'] += orders_list[i]['qty'] * parseFloat(orders_list[i]['price'])
              }
          }
        }
      }
      
      

    });

    const daily_reportArray = Array()

    const transactions_dates = Array()

    Object.keys(report_init).forEach(key => {
      let date_obj = {}
      date_obj['date'] = key
      transactions_dates.push(key)
      date_obj['no_transactions'] = report_init[key]['no_transactions']
      date_obj['grand_total'] = report_init[key]['daily_total']
      date_obj['daily_added_discounts'] = report_init[key]['daily_added_discounts']

      let transactions = report_init[key]['order_items']
      let order_items = Array()
      Object.keys(transactions).forEach(item_id => {
        let order_obj = {}
        order_obj['product_id'] = item_id
        order_obj['product_img'] = transactions[item_id]['img']
        order_obj['product_name'] = transactions[item_id]['product_name']

        let sizes = transactions[item_id]['items']
        let size_items = Array()
        Object.keys(sizes).forEach(size_name => {
            let size_obj ={}
            size_obj['size_name'] = size_name
            size_obj['qty'] = sizes[size_name]['qty']
            size_obj['subtotal'] = sizes[size_name]['total']

            size_items.push(size_obj)
        })

        order_obj['splits'] = size_items
        order_items.push(order_obj)


      })
      date_obj['order_items'] = order_items

      daily_reportArray.push(date_obj)

      
    });

    const week_group = groupByWeeks(transactions_dates)
    const month_group = groupByMonths(transactions_dates)

    //console.log(findDateInGroups('2024-02-08',week_group))


   
    //console.log(week_group,month_group)

    


    const weekObj = {}
    daily_reportArray.map((day_report)=>{
        const week_name = findDateInGroups(day_report.date,week_group)
        if(weekObj[week_name] == undefined){
          weekObj[week_name] = {}
          weekObj[week_name]['weekly_added_discounts'] = 0
          weekObj[week_name]['weekly_total'] = 0
          weekObj[week_name]['no_transactions'] = 0
          weekObj[week_name]['order_items'] = {}

        }

          weekObj[week_name]['weekly_added_discounts'] = weekObj[week_name]['weekly_added_discounts'] + day_report['daily_added_discounts']
          weekObj[week_name]['weekly_total'] =  weekObj[week_name]['weekly_total'] + day_report['grand_total']
          weekObj[week_name]['no_transactions'] = weekObj[week_name]['no_transactions'] + day_report['no_transactions']



          let order_items = day_report['order_items']

          order_items.map((order)=> {
            if(weekObj[week_name]['order_items'][order.product_id] == undefined){
              weekObj[week_name]['order_items'][order.product_id]={}
              weekObj[week_name]['order_items'][order.product_id]['spec'] = {}
              weekObj[week_name]['order_items'][order.product_id]['spec']['product_img'] = order.product_img
              weekObj[week_name]['order_items'][order.product_id]['spec']['product_name'] = order.product_name
              weekObj[week_name]['order_items'][order.product_id]['splits'] = {}
            }

            let spilt_items = order.splits

            spilt_items.map((split)=> {
              if(weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name] == undefined){
                weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name] = {}
                weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name]['qty'] = split.qty
                weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name]['subtotal'] = split.subtotal

              }else{
                weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name]['qty'] += split.qty
                weekObj[week_name]['order_items'][order.product_id]['splits'][split.size_name]['subtotal'] += split.subtotal
              }

              
            })
            })
      });

      const weekly_reportArray = Array()

      Object.keys(weekObj).forEach(key => {
        let date_obj = {}
        date_obj['date'] = key
        date_obj['no_transactions'] = weekObj[key]['no_transactions']
        date_obj['grand_total'] = weekObj[key]['weekly_total']
        date_obj['weekly_added_discounts'] = weekObj[key]['weekly_added_discounts']
  
        let transactions = weekObj[key]['order_items']
        let order_items = Array()
        Object.keys(transactions).forEach(item_id => {
          let order_obj = {}
          order_obj['product_id'] = item_id
          order_obj['product_img'] = transactions[item_id]['spec']['product_img']
          order_obj['product_name'] = transactions[item_id]['spec']['product_name']
  
          let sizes = transactions[item_id]['splits']
          let size_items = Array()
          Object.keys(sizes).forEach(size_name => {
              let size_obj ={}
              size_obj['size_name'] = size_name
              size_obj['qty'] = sizes[size_name]['qty']
              size_obj['subtotal'] = sizes[size_name]['subtotal']
  
              size_items.push(size_obj)
          })
  
          order_obj['splits'] = size_items
          order_items.push(order_obj)
  
  
        })
        date_obj['order_items'] = order_items
  
        weekly_reportArray.push(date_obj)
  
        
      });

      const monthObj = {}
      daily_reportArray.map((day_report)=>{
          const month_name = findDateInGroups(day_report.date,month_group)
          if(monthObj[month_name] == undefined){
            monthObj[month_name] = {}
            monthObj[month_name]['monthly_added_discounts'] = 0
            monthObj[month_name]['monthly_total'] = 0
            monthObj[month_name]['no_transactions'] = 0
            monthObj[month_name]['order_items'] = {}
  
          }
  
          monthObj[month_name]['monthly_added_discounts'] = monthObj[month_name]['monthly_added_discounts'] + day_report['daily_added_discounts']
          monthObj[month_name]['monthly_total'] =  monthObj[month_name]['monthly_total'] + day_report['grand_total']
          monthObj[month_name]['no_transactions'] = monthObj[month_name]['no_transactions'] + day_report['no_transactions']
  
  
  
            let order_items = day_report['order_items']
  
            order_items.map((order)=> {
              if(monthObj[month_name]['order_items'][order.product_id] == undefined){
                monthObj[month_name]['order_items'][order.product_id]={}
                monthObj[month_name]['order_items'][order.product_id]['spec'] = {}
                monthObj[month_name]['order_items'][order.product_id]['spec']['product_img'] = order.product_img
                monthObj[month_name]['order_items'][order.product_id]['spec']['product_name'] = order.product_name
                monthObj[month_name]['order_items'][order.product_id]['splits'] = {}
              }
  
              let spilt_items = order.splits
  
              spilt_items.map((split)=> {
                if(monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name] == undefined){
                  monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name] = {}
                  monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name]['qty'] = split.qty
                  monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name]['subtotal'] = split.subtotal
  
                }
                else{
                  monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name]['qty'] += split.qty
                  monthObj[month_name]['order_items'][order.product_id]['splits'][split.size_name]['subtotal'] += split.subtotal
                }
  
                
              })
              })
        });

        const monthly_reportArray = Array()

        Object.keys(monthObj).forEach(key => {
          let date_obj = {}
          date_obj['date'] = key
          date_obj['no_transactions'] = monthObj[key]['no_transactions']
          date_obj['grand_total'] = monthObj[key]['monthly_total']
          date_obj['monthly_added_discounts'] = monthObj[key]['monthly_added_discounts']
    
          let transactions = monthObj[key]['order_items']
          let order_items = Array()
          Object.keys(transactions).forEach(item_id => {
            let order_obj = {}
            order_obj['product_id'] = item_id
            order_obj['product_img'] = transactions[item_id]['spec']['product_img']
            order_obj['product_name'] = transactions[item_id]['spec']['product_name']
    
            let sizes = transactions[item_id]['splits']
            let size_items = Array()
            Object.keys(sizes).forEach(size_name => {
                let size_obj ={}
                size_obj['size_name'] = size_name
                size_obj['qty'] = sizes[size_name]['qty']
                size_obj['subtotal'] = sizes[size_name]['subtotal']
    
                size_items.push(size_obj)
            })
    
            order_obj['splits'] = size_items
            order_items.push(order_obj)
    
    
          })
          date_obj['order_items'] = order_items
    
          monthly_reportArray.push(date_obj)
    
          
        });
    

    
    final_out['daily'] = daily_reportArray
    final_out['weekly'] = weekly_reportArray
    final_out['monthly'] = monthly_reportArray

    return final_out

}

export async function getCategoriesStatic(){

  const catq = query(collection(db, 'category'),orderBy("category_name"));
   
 
  const categorySnapshot = await getDocs(catq);

  const final_out = {}

  const categoryList = Array();

  categorySnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id
    categoryList.push(data)

    
  });

  final_out['data'] = categoryList

  return final_out

}

export async function getPaymentInfo(){

  const payemtq = query(collection(db, 'payment-info'));
   
 
  const paymentSnapshot = await getDocs(payemtq);

  const final_out = {}

  

  const paymentList = Array();

  

  paymentSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id
    paymentList.push(data)

    
  });

  final_out['data'] = paymentList[0]

  return final_out

}

export async function getAccountInfo(){

  const accountq = query(collection(db, 'account-info'));
   
 
  const accountSnapshot = await getDocs(accountq);

  const final_out = {}

  

  const accountList = Array();

  

  accountSnapshot.forEach((doc) => {
    const data = doc.data();
    data["_id"] = doc.id
    accountList.push(data)

    
  });

  final_out['data'] = accountList[0]

  return final_out

}

async function fileUpload(folder = 'temp',img,image_name){
  
  var final_url = undefined
  if (img.name){

    const extension = img.name.split(".").pop();

    const filename = image_name + "." + extension

    const fileRef = storageRef(storage, folder+ filename);

    await uploadBytes(fileRef, img);

    const url = getDownloadURL(fileRef)
      final_url = url

  }

  return final_url

}

export async function createDocument(col_name,form){
  const docRef = await addDoc(collection(db, col_name), form);
  return docRef
}

export async function setSubDocument(col_name,insertref,form){
  const docRef = await setDoc(doc(db, col_name, insertref), form);
  return docRef
}



export async function updateDocument(upRef,form){
  const docRef = await updateDoc(upRef, form);
  return docRef
}





export async function createMenuItem(token,form,img){
  const final_out = {}

  if(token.length > 0){

    try{
      if(img && img.name != ""){
          fileUpload('menu/',img,form.name).then((url) =>{

          const final_url = url

          if(final_url){
            form.img = final_url;
          }
          
        });
      }
        const docRef =  createDocument('menu',form)
        final_out['msg'] = "Item added successfully."
      
    }
    catch(error){
      final_out['msg'] = error.message
    }
  }
  else
  {
    final_out['msg'] = "User has no access to add item."
  }
  return final_out
}

export async function addUserFeedback(feedback){
  const final_out = {}
  try{
    const docRef =  createDocument('feedbacks',feedback)
    final_out['msg'] = "Feedback added successfully."
  }
  catch(error){
    final_out['msg'] = error.message
  }
  return final_out
}

export async function createPromoItem(token,form,img){
  const final_out = {}

  if(token.length > 0){

    const imgname = form.coupon_code + '_' + form.end_date.seconds

    try{
      if(img && img.name != ""){
          fileUpload('discounts/',img,imgname).then((url) =>{

          const final_url = url

          if(final_url){
            form.img = final_url;
          }
          
        });
      }
        const docRef =    createDocument('discounts',form)
        final_out['msg'] = "Promo added successfully."
      
    }
    catch(error){
      final_out['msg'] = error.message
    }
  }
  else
  {
    final_out['msg'] = "User has no access to add item."
  }
  return final_out
}

export async function updateMenuItem(productId,token,form,img){
  
  const final_out = {}
 


  const docRef = doc(db, "menu", productId);

  if(token.length > 0){
    try{
      if (img != ''){
        fileUpload('menu/',img,form.name).then((url) =>{

        const final_url = url

        if(final_url){
          form.img = final_url;
        }
      
        const docupdateRef =  updateDocument(docRef,form)
        final_out['msg'] = "Item updated successfully."
      });
    }else{
      const docupdateRef =  updateDocument(docRef,form)
      final_out['msg'] = "Item updated successfully."
    }
    }
    catch(error){
      final_out['msg'] = error
    }
  }
  else
  {
    final_out['msg'] = "User has no access to update item."
  }
  return final_out
}

export async function updatePromoItem(promoId,token,form,img){
  
  const final_out = {}

  const imgname = form.coupon_code + '_' + form.end_date.seconds
 


  const docRef = doc(db, "discounts", promoId);

  if(token.length > 0){
    try{
      if (img != ''){
        fileUpload('discounts/',img,imgname).then((url) =>{

        const final_url = url

        if(final_url){
          form.img = final_url;
        }
        
        const docupdateRef =  updateDocument(docRef,form)
        final_out['msg'] = "Promo updated successfully."
        
      });

    }
    else{
      const docupdateRef =  updateDocument(docRef,form)
      final_out['msg'] = "Promo updated successfully."
    }
    
      
    
    }
    catch(error){
      final_out['msg'] = error
    }
  }
  else
  {
    final_out['msg'] = "User has no access to update item."
  }
  return final_out
}


export async function getFbMenuItembyId(product_id){
  const final_out = {}
  const docRef = doc(db, "menu", product_id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const documentData = docSnap.data();
      if(documentData['sizes']){
        const sizes = Object.values(documentData['sizes'])
      documentData['sizes'] = sizes
      }
      
      final_out['data'] = documentData

      return final_out
      
    } else {
      final_out['data'] = {}

      return final_out
    }
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

export async function getFbPromoById(promo_id){
  const final_out = {}
  const docRef = doc(db, "discounts", promo_id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const documentData = docSnap.data();

      var start_date_timestamp = documentData.start_date;
      var end_date_timestamp = documentData.end_date;

      documentData.start_date = new Date(start_date_timestamp.seconds * 1000 + start_date_timestamp.nanoseconds / 1000000);
      documentData.end_date = new Date(end_date_timestamp.seconds * 1000 + end_date_timestamp.nanoseconds / 1000000);

      final_out['data'] = documentData

      return final_out
      
    } else {
      final_out['data'] = {}

      return final_out
    }
  } catch (error) {
    console.error("Error getting document:", error);
  }
}




export async function deleteFbMenuItembyId(product_id){
  const final_out = {}

  const docRef = doc(db, "menu", product_id);
  try {

    const docSnap = await deleteDoc(docRef);
    
      
      final_out['msg'] = "Successfully deleted item."

      return final_out
   
  } catch (error) {
    final_out['msg'] = "Failed to delete item."
    console.error("Error getting document:", error);
  }
}

export async function deleteFbPromoItembyId(promo_id){
  const final_out = {}

  const docRef = doc(db, "discounts", promo_id);
  try {

    const docSnap = await deleteDoc(docRef);
    
      
      final_out['msg'] = "Successfully deleted promo."

      return final_out
   
  } catch (error) {
    final_out['msg'] = "Failed to delete promo."
    console.error("Error getting document:", error);
  }
}


export async function createTransaction(token,form,orders){
  const final_out = {}

  final_out['token'] = token
  final_out['form'] = form

  if (token === "" && token?.length < 1) {
    final_out['msg'] = "User has no access to create order."
    return final_out
  }

    try{
        
        if (form){
          const docRef =  await  createDocument('transactions',form)
           
          final_out['insertRef'] = docRef.id
          
          
          //setSubDocument('transactions',docRef.id,orderData)
          final_out['msg'] = "Success create transaction"
        }
        else{
          final_out['msg'] = "Unknown error occured"
        }
         
    }
    catch(error){
      final_out['msg'] = error.message
    }
  
  
  return final_out
}

export async function confirmTransaction(transactionId,token){
  const final_out = {}
 


  const docRef = doc(db, "transactions", transactionId);

  if(token.length > 0){
    try{
      const form = {}
      form["order_status"] = 2
      const docupdateRef =  updateDocument(docRef,form)
      final_out['msg'] = "Transaction confirmed successfully."
    }
    catch(error){
      final_out['msg'] = error
    }
  }
  else
  {
    final_out['msg'] = "User has no access to confirm transaction."
  }
  return final_out
}









