// Helper function to parse dates and return a Date object
function parseDate(dateString) {
    return new Date(dateString);
  }
  
  // Group by weeks
  export function groupByWeeks(dates) {
    const weekGroups = {};
    dates.forEach(dateString => {
      const date = parseDate(dateString);
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const key = startOfWeek.toISOString().split('T')[0];
      if (!weekGroups[key]) {
        weekGroups[key] = [];
      }
      weekGroups[key].push(dateString);
    });
    return weekGroups;
  }
  
  // Group by months
  export function groupByMonths(dates) {
    const monthGroups = {};
    dates.forEach(dateString => {
      const date = parseDate(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() is zero-indexed
      const key = year + "-" + month.toString().padStart(2, '0')
      if (!monthGroups[key]) {
        monthGroups[key] = [];
      }
      monthGroups[key].push(dateString);
    });
    return monthGroups;
  }

  export function findDateInGroups(dateString,dateGroup){
    for (const [start,dates] of Object.entries(dateGroup)){
        if(dates.includes(dateString)){
            return start
        }
    }
  }

  export function getReportInRange(input_report,view,start_date,end_date){
        // console.log(input_report)
        const final_report = {}
        final_report['no_transactions'] = 0
        final_report['grand_total'] = 0
        final_report['added_discounts'] = 0
        const order_items = {}
        final_report['order_items'] = []
        input_report.map((trans) =>{
            let trans_date = trans.date
            if(trans_date >= start_date && trans_date <= end_date){
                final_report['no_transactions'] = final_report['no_transactions'] + trans.no_transactions
                final_report['grand_total'] = final_report['grand_total'] + trans.grand_total
                
                var trans_discount = 0
                if(view == "daily"){
                    trans_discount = trans['daily_added_discounts']
                    
                }
                if(view == "weekly"){
                    trans_discount = trans['weekly_added_discounts']
                }
                if(view == "monthly"){
                    trans_discount =trans['monthly_added_discounts']
                }

                
                final_report['added_discounts'] = final_report['added_discounts'] + trans_discount
                

                const items = trans['order_items']

                items.map((item) =>{
                     if(order_items[item.product_id] == undefined){
                        order_items[item.product_id] = {}
                        order_items[item.product_id]['splits']  = {}
                        order_items[item.product_id]['product_id']= item['product_id']
                        order_items[item.product_id]['product_img']= item['product_img']
                        order_items[item.product_id]['product_name']= item['product_name']
                     }   
                     

                     const splits = item.splits
                     splits.map((split) =>{
                        if(order_items[item.product_id]['splits'][split.size_name] == undefined){
                            order_items[item.product_id]['splits'][split.size_name] = {}
                            order_items[item.product_id]['splits'][split.size_name]['size_name'] = split.size_name
                            order_items[item.product_id]['splits'][split.size_name]['qty'] = 0
                            order_items[item.product_id]['splits'][split.size_name]['subtotal'] = 0
                        }
                        
                        order_items[item.product_id]['splits'][split.size_name]['qty'] = order_items[item.product_id]['splits'][split.size_name]['qty'] + split['qty']
                        order_items[item.product_id]['splits'][split.size_name]['subtotal'] = order_items[item.product_id]['splits'][split.size_name]['subtotal'] + split['subtotal']
                        
                        
                     })

                })

                
                

            }
        })

        Object.keys(order_items).forEach(product_id => {
            const order_obj = {}
            order_obj['product_id'] = order_items[product_id]['product_id']
            order_obj['product_name'] = order_items[product_id]['product_name']
            order_obj['product_img'] = order_items[product_id]['product_img']
            order_obj['splits'] = []
            const splits = order_items[product_id]['splits']
            Object.keys(splits).forEach(size_name => {
           
                const size_obj = {}
                size_obj['size_name'] = splits[size_name]['size_name']
                size_obj['qty'] = splits[size_name]['qty']
                size_obj['subtotal'] = splits[size_name]['subtotal']
                order_obj['splits'].push(size_obj)
            })
            final_report['order_items'].push(order_obj)
        })

        //console.log(final_report)

        return final_report
        
  }