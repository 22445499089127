import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import fbLogo from '../assets/icons/facebook.svg';
import igLogo from '../assets/icons/instagram.svg';
import twLogo from '../assets/icons/twitter.svg';
import logo from '../assets/chaiguru_logo.png';

class Footer extends Component {
  render() {
    return (
      <footer className="bg-[#F8F8F8] text-[#4f5665]">
        <div className="global-px">
          <div className="py-5  md:py-20"></div>
          <div className="flex flex-col-reverse gap-12 md:flex-row">
            <div className="flex flex-col gap-4 md:flex-[2_2_0%]">
              <Link to="/">
                <div className="font-extrabold flex flex-row gap-2">
                  <img src={logo} alt="logo" width="90px" />{" "}
                  
                </div>
              </Link>
              <div className="md:w-96">
                Chai guru is the store from family flavors group.
              </div>
              <div className="flex flex-row gap-5">
                
                <a
                  href="https://www.facebook.com/profile.php?id=61555667535269&mibextid=ZbWKwL"
                  className="bg-tertiary h-[35px] w-[35px] flex items-center justify-center rounded-full"
                  aria-label="Facebook"
                >
                  <img src={fbLogo} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/chaiguru_koothattukulam/"
                  className="bg-tertiary h-[35px] w-[35px] flex items-center justify-center rounded-full"
                >
                  <img src={igLogo} alt="" />
                </a>
                
              </div>
              <div className="copyright">(c) 2024 family flavors</div>
            </div>
            
          </div>
          <div className="py-5"></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
