import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs ,query, where } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword,signOut } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCxHWR8oUedd9QDV-qyo7JETz4V3mhaIig",
  authDomain: "chai-guru.firebaseapp.com",
  projectId: "chai-guru",
  storageBucket: "chai-guru.appspot.com",
  messagingSenderId: "226264376241",
  appId: "1:226264376241:web:e3317191422e8fde4a5390"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);


async function _login(email, password, rememberMe) {

    
    

    const message = {}
   

    
    
    try{
    
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        if(userCredential.user){
            message['message'] = "Login exists."
            message['token'] = userCredential.user.accessToken

            
            const q = query(collection(db, 'users'), where("email", "==", email));
            const userSnapshot = await getDocs(q);
            const user = userSnapshot.docs.map(doc => doc.data());
            message['profile'] = {}
            message['profile']['status'] = "200"
            message['profile']['msg'] = "Login successfull"
            message['profile']['data'] = user[0]
        }
        else{
            message['profile']['status'] = "500"
            message['profile']['msg'] = "Failed to login"
            message['message'] = "Failed to login."
        }
   

    }
    catch (error){
        message['message'] = error.message;
    }

    return message


  }

  export  function login(email, password, rememberMe){
   return  _login(email, password, rememberMe)
  }


  export function signout(){
    const logout = signOut(auth);
        return logout;
      
  }

