import 'react-loading-skeleton/dist/skeleton.css';

import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';

import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import productPlaceholder from "../../assets/images/placeholder-image.webp";

import Footer from '../../components/Footer';
import Header from '../../components/Header';
//import { getSellingReport } from '../../utils/dataProvider/admin';
import useDocumentTitle from '../../utils/documentTitle';

import {getSellingReport } from "../../utils/dataProvider/fb_orders"

import {
  n_f,
  short_n_f,
  inr_f,
} from '../../utils/helpers';

import {getReportInRange} from '../../utils/fb_helper';

const AdminDashboard = (props) => {
  useDocumentTitle("Admin Dashboard");
  const [data, setData] = useState([]);
  const [report, setReport] = useState();
  const [summaryLoaded,setSummaryLoaded] = useState(false);
  const [summary, setSummary] = useState({
    no_transactions:0,
    grand_total:0,
    added_discounts:0,
    order_items:[],
  });


  const [searchParams, setSearchParams] = useSearchParams({ view: "daily" });
  const view = searchParams.get("view") || "daily";
  // const controller = useMemo(() => new AbortController(), [view]);
  const [loading, setLoading] = useState(true);

  let start_date_formated 
  let end_date_formated 

  const [form, setForm] = useState({
    
    // start_date: start_date_formated, // real form
    // end_date: end_date_formated, // real form
    startDate: null,
    endDate: null,
  });


  if(view == "monthly"){
    const end_date = new Date()
    const start_date = new Date()
    start_date.setMonth(start_date.getMonth() - 6)

     start_date_formated = start_date.getFullYear() + "-" + ('0' + (start_date.getMonth() + 1)).slice(-2) 
    end_date_formated = end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + 1)).slice(-2)

  
  }else if(view == "weekly"){

    const end_date = new Date()
    const start_date = new Date()
    start_date.setDate(start_date.getDate() - 30)

    start_date_formated = start_date.getFullYear() + "-" + ('0' + (start_date.getMonth() + 1)).slice(-2) + "-" +  ('0' + (start_date.getDate())).slice(-2)
    end_date_formated = end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + 1)).slice(-2) + "-" +  ('0' + (end_date.getDate())).slice(-2)

    

  }else{

    const end_date = new Date()
    const start_date = new Date()
    start_date.setDate(start_date.getDate() - 7)

    start_date_formated = start_date.getFullYear() + "-" + ('0' + (start_date.getMonth() + 1)).slice(-2) + "-" +  ('0' + (start_date.getDate())).slice(-2)
    end_date_formated = end_date.getFullYear() + "-" + ('0' + (end_date.getMonth() + 1)).slice(-2) + "-" +  ('0' + (end_date.getDate())).slice(-2)

    

  }

  
  


 
  // searchParams.get("view"), props.userInfo.token
  const getReport = () => {
    setLoading(true);
    getSellingReport()
      .then((result) => {
        
        
        setReport(result);
         
        setData(result[view.toLowerCase()])
        
        

        let view_report = getReportInRange(result[view.toLowerCase()],view,start_date_formated,end_date_formated)
        // console.log(view_report)

        setSummary(view_report)

        
      })
      .catch((err) => {
        
        setLoading(false);
        console.log(err);
      })
      .finally(() => {
        
        
        setSummaryLoaded(true)

        setLoading(false);
        setForm({startDate: start_date_formated, endDate:  end_date_formated  });
      });

    }
 
   
    
    
    
    useEffect(() => {
      
        getReport();
        
    }, []);

    useEffect(() => {
      setLoading(true);
      setForm({startDate: start_date_formated, endDate:  end_date_formated  });
      if(report == undefined){
        getReport();
      }
      else{

        setData(report[view.toLowerCase()])
       
      }
      
      setLoading(false);
    }, [view]);


    useEffect(() => {
      //console.log(form.startDate)
      
      let view_report = getReportInRange(data,view,form.startDate,form.endDate)
      
      setSummary(view_report)
      setSummaryLoaded(true)
    }, [form.startDate,form.endDate]);
    
   
  

  let title = "";
  let subtitle = "";
  let format = "YYYY-MM-DD";
  switch (view) {
    case "daily":
      title = "Daily Report";
      subtitle = "Last 7 days include today";
      format = "YYYY-MM-DD";
      break;
    case "weekly":
      title = "Weekly Report";
      subtitle = "Last 7 week include current week";
      format = "YYYY-MM-DD";
      break;

    default:
      title = "Monthly Report";
      subtitle = "Last 7 months";
      format = "YYYY-MM";
      break;
  }

  // Find the maximum value in the dataset
  const maxValue = Math.max(...data.map((item) => item.grand_total));
  return (
    <>
      <Header />
      <main className="bg-[#F0F0F0]">
        <section className="flex flex-col global-px py-4 md:py-8">
          <p className="text-center text-tertiary font-bold text-2xl">
            Chai guru progress
          </p>
          <div className="flex gap-12 justify-center pt-5">
            <div
              className="flex flex-col items-center gap-2 cursor-pointer w-12"
              onClick={() => setSearchParams({ view: "daily" })}
            >
              <div
                className={`h-8 w-8 border-8 rounded-full duration-200 ${
                  view === "daily"
                    ? "bg-secondary border-tertiary"
                    : "bg-white border-gray-400"
                }`}
              ></div>
              <p className={`${view === "daily" && "font-bold"} `}>Daily</p>
            </div>
            <div
              className="flex flex-col items-center gap-2 cursor-pointer w-12"
              onClick={() => setSearchParams({ view: "weekly" })}
            >
              <div
                className={`h-8 w-8 border-8 rounded-full duration-200 ${
                  view === "weekly"
                    ? "bg-secondary border-tertiary"
                    : "bg-white border-gray-400"
                }`}
              ></div>
              <p className={`${view === "weekly" && "font-bold"}`}>Weekly</p>
            </div>
            <div
              onClick={() => setSearchParams({ view: "monthly" })}
              className="flex flex-col items-center gap-2 cursor-pointer w-12"
            >
              <div
                className={`h-8 w-8 border-8 rounded-full duration-200 ${
                  view !== "weekly" && view !== "daily"
                    ? "bg-secondary border-tertiary"
                    : "bg-white border-gray-400"
                }`}
              ></div>
              <p
                className={`${
                  view !== "weekly" && view !== "daily" && "font-bold"
                }`}
              >
                Monthly
              </p>
            </div>
          </div>
        </section>

        <section className="flex flex-col md:flex-row global-px py-4 md:py-8 gap-8">
          {loading || data.slice(0, 7).length < 1 ? (
            <section className="flex-[5_5_0%]">
              <Skeleton
                className="flex-[5_5_0%] h-[600px] md:h-full"
                baseColor="#ccc"
                width="100%"
              />
            </section>
          ) : (
            <section className="flex-auto flex flex-col bg-white rounded-lg shadow-lg p-8">
              <p className="text-xl md:text-2xl font-bold text-black">
                {title}
              </p>
              <p className="text-base md:text-lg text-primary-context mt-2">
                {subtitle}
              </p>
              <section className="flex gap-2 md:gap-5 mt-5 md:mt-auto mb-5">
                <div className="hidden md:flex h-96 flex-col justify-between">
                  <span className="text-primary-context text-sm md:text-base lg:text-lg">
                    {inr_f(maxValue)}
                  </span>
                  <span className="text-primary-context text-sm md:text-base lg:text-lg">
                    {inr_f(maxValue / 2)}
                  </span>
                  <span className="text-primary-context mb-5 text-sm md:text-base lg:text-lg">
                     0
                  </span>
                </div>
                <div className="flex items-end h-96 relative justify-between flex-1">
                  <div className="absolute h-1 border-b border-dotted border-primary-context w-full bottom-8"></div>
                  {data.map((item, key) => (
                    <div
                      key={key}
                      className="flex flex-col items-center md:mx-2 lg:mx-4 h-full"
                    >
                      <div
                        style={{
                          height: `${(item.grand_total / maxValue) * 100}%`,
                        }}
                        className="bg-secondary mb-2 w-5 rounded-md mt-auto relative group"
                      >
                        <div className="absolute hidden group-hover:block bg-tertiary/80 backdrop-blur-md p-3 text-white text-center rounded-lg z-20 -top-10 text-xs">
                          
                          <p className='text-inline'>Orders: <span className='text-xs'>{item.no_transactions}</span> </p>
                          <hr/>
                          <p>Total: <span className='text-xs'>{inr_f(item.grand_total)}</span> </p>
                        </div>
                      </div>
                      <span
                        className={`text-primary-context mt-2 h-6 text-center ${
                          item.date.length > 3
                            ? "text-xs sm:text-xs"
                            : "text-sm sm:text-base md:text-lg"
                        }`}
                      >
                        {item.date}
                      </span>
                    </div>
                  ))}
                </div>
              </section>
              <hr />
              <div className="mt-5 flex flex-row justify-center items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-secondary"></div>{" "}
                <p className="text-sm text-primary-context">Income</p>
              </div>
            </section>
          )}

        
          
        
        <section className="flex-auto flex flex-col bg-white rounded-lg shadow-lg p-8  gap-3">
          
                  <p className="text-black text-lg font-bold text-center">{title}</p>
              
               
                 <div className='flex item-center'>
                  <Datepicker
                    // containerClassName={"bg-white"}
                    inputClassName={
                "bg-white border-b-2 py-2 border-gray-300 focus:border-tertiary outline-none w-full"
                      
                    }
                    // containerClassName="relative" 
                    startFrom={new Date().setDate(new Date().getDate() - 7)} 
                    minDate={new Date("2023-12-31")}
                
                    maxDate={new Date()} 
                    placeholder={"Date range"}
                    value={form}
                    popoverDirection="up"
                    displayFormat={format} 
                    separator="to"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        startDate: e.startDate,
                        endDate: e.endDate,
                        start_date: e.startDate,
                        end_date: e.endDate,
                      })
                    }
                    showShortcuts={false} 
                    configs={{
                      shortcuts: {
                      last3Days: { 
                      text: "Last 3 days",
                      period: {
                      start: "2024-02-06",
                      end: "2024-02-08"
                      }, 
                      }, 
                      yesterday: "Yesterday",
                      customToday: { 
                      text: "Custom Today",
                      period: {
                      start: "2024-02-09",
                      end: "2024-02-09"
                      }, 
                      }, 
                      
                      }
                      }} 
                  />

              </div>
                  
                
                  
             {
              summaryLoaded && 
              <>
              <div className="flex  text-primary-context text-center item-center">
                  
                  <div className="flex-auto item-center">
                    {/* <p className="text-black font-bold">Chesya Laurent</p> */}
                    <p className="text-black text-tertiary text-base">
                      No. of transactions : <span className='font-bold'> {summary.no_transactions} </span>
                    </p>
                    {/* <p className="text-black text-sm">
                      Total collection : {inr_f(summary.grand_total)}
                    </p> */}
                    <p className="text-black text-tertiary text-base">
                      Total discount given : <span className='font-bold'> {inr_f(summary.added_discounts)}</span>
                    </p>
                    <p className="text-black text-tertiary text-base">
                      Grand total :  <span className='font-extrabold'> {inr_f(summary.grand_total)} </span>
                    </p>
                  </div>
                  <hr />
                </div>

              {/* <p className="text-center">
              
              </p> */}

          { summary.order_items.length > 0 &&
          <table className="table-fixed border-collapse border border-slate-400">
            <caption className="caption-top">
            Item sales report
            </caption>
            <thead>
              <tr className='text-sm  text-primary-context'>
                <th className="border border-slate-300">SI.No.</th>
                <th className="border border-slate-300">Item Name</th>
                <th>

                </th>
                <th className="border border-slate-300">Split</th>
              </tr>
            </thead>
          
            <tbody>
            {summary.order_items.map((item,idx) => (
              <tr  key={idx}>
                <td className="border border-slate-300">
                  <p className='text-center'>{idx + 1}</p>
                
                </td>
                <td className="border border-slate-300 pl-2">
                <div className="flex gap-4 mb-2 ">
               

                <div className="my-auto">
                <div className="avatar align-middle">
                <div className="w-12 rounded-full object-center">
                                      <img
                                        src={
                                          item.product_img ||
                                          productPlaceholder
                                        }
                                      />
                                    </div>
                </div>
                </div>
                
                <div className="my-auto">
                <p className='text-left sm:text-sm md:text-sm lg:text-base align-middle'>
                  {item.product_name}
                </p>
                </div>
                </div>
                </td>
                <td></td>
                <td className="text-sm border border-slate-300 pl-2">
                {item.splits.map((split,ids) => (
                    <p key={ids} className='text-left text-xs'>{split.size_name} ({inr_f(split.subtotal / split.qty) }) X <span className='font-semibold'>{split.qty}</span> = <span className='font-bold'>{inr_f(split.subtotal)}</span></p>
                  ))
                  }
                </td>
              </tr>
            ))}
            </tbody>
            </table>
          } 





              </>

             }
             
            
        </section>
      </section>

        {/* <section className="flex global-px pb-8 gap-8">
          <section className="flex-1 md:flex-[5_5_0%] flex bg-tertiary rounded-lg hover:bg-primary-focus duration-200 group">
            <button className="btn btn-block btn-primary btn-lg text-white normal-case text-lg group-hover:bg-primary-focus border-0">
              Download Report
            </button>
          </section>
          <section className="flex-1 md:flex-[2_2_0%] flex flex-col gap-8">
            <button className="btn btn-block btn-primary btn-lg text-white normal-case text-lg">
              Share Report
            </button>
          </section>
        </section> */}
      </main>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
