import { getDiscountStatic,getCategoriesStatic ,createPromoItem , getFbPromoById, updatePromoItem,deleteFbPromoItembyId} from "./firebaseConn"
import { Timestamp } from 'firebase/firestore'

export const getPromos = (isAdmin = false,
    { page = 1, limit = 4, available = "true"},
  ) => {
    const params = {
      page,
      limit,
      available
    };
    return getDiscountStatic(isAdmin,{ page, limit, available})
  };


  export const getCategories = () => {
    
    return getCategoriesStatic()
  };

  

  export const deletePromoEntry = (promoId, token) => {
    if(token.length > 0){
        return deleteFbPromoItembyId(promoId);
    }
    
};


 

  export const createPromoEntry = (
    { name , desc,startDate, endDate ,coupon_code, image = "" },
    token
  ) => {
    const bodyForm = {}
   
    bodyForm.name =  name;
    bodyForm.desc =  desc;
    bodyForm.coupon_code =  coupon_code.toUpperCase();
    
    bodyForm.start_date =  Timestamp.fromDate(new Date(startDate));
    bodyForm.end_date =  Timestamp.fromDate(new Date(endDate));
  
    
   return createPromoItem(token,bodyForm,image);
  };

 

  export const editPromoEntry  = (promoId,
    { name , desc,startDate, endDate ,coupon_code, image = "" },
    token
    ) => {
    const bodyForm = {}
   
    bodyForm.name =  name;
    bodyForm.desc =  desc;
    bodyForm.coupon_code =  coupon_code.toUpperCase();
    
    bodyForm.start_date =  Timestamp.fromDate(new Date(startDate));
    bodyForm.end_date =  Timestamp.fromDate(new Date(endDate));
  
    
    return updatePromoItem(promoId,token,bodyForm,image);
  };

  export const getPromoById = (promo_id) => {

    return getFbPromoById(promo_id);

    }


  function testApi(test){
    return(test)
  }