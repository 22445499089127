import React, { useEffect, useMemo, useRef, useState } from "react";

import axios from "axios";
import { toast } from "react-hot-toast";
// import _ from "lodash";
import { connect } from "react-redux";

import loadingImage from "../../assets/images/loading.svg";
import productPlaceholder from "../../assets/images/placeholder-image.webp";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";

import useDocumentTitle from "../../utils/documentTitle";
import { n_f, inr_f ,timespan_date } from "../../utils/helpers";

import {getAllTransactions, setTransactionDone} from "../../utils/dataProvider/fb_orders"

const ManageOrder = (props) => {
  const carouselRef = useRef(null);
  const [focusedCard, setFocusedCard] = useState(0);
  const [order, setOrder] = useState([]);
  const [done, setDone] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  useDocumentTitle("Manage Order");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = carouselRef.current.scrollLeft;
      const cardWidth = carouselRef.current.offsetWidth;
      const focusedCardIndex = Math.round(scrollPosition / cardWidth);
      setFocusedCard(focusedCardIndex);
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  const scrollLeft = () => {
    const cardWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollLeft -= cardWidth;
  };

  const scrollRight = () => {
    const cardWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollLeft += cardWidth;
  };
  const controller = useMemo(() => new AbortController(), []);
  const fetch = () => {
    setLoading(true);
    getAllTransactions( props.userInfo.token)
      .then((result) => {
        //console.log(result)
        setOrder(result.last_orders);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    fetch();
  }, []);
  const disabled = doneLoading;
  const doneHandler = () => {
    if (!done) return setDone(true);
    setDoneLoading(true);
    setDone(false);
    setTransactionDone(order[focusedCard]._id, props.userInfo.token)
      .then((result) => {
        toast.success("Success process transactions");
        setDoneLoading(false);
        fetch();
      })
      .catch((err) => {
        setDoneLoading(false);
        if (axios.isCancel(err)) return;
        toast.error("Error while processing");
      });
  };
  const closeDoneModal = () => setDone(false);
  return (
    <>
      <Modal isOpen={done} onClose={closeDoneModal}>
        <p>Are you sure to set processed this order?</p>
        <div className="mt-4 mx-auto space-x-2">
          <button onClick={doneHandler} className="btn btn-primary">
            Yes
          </button>
          <button onClick={closeDoneModal} className="btn btn-error">
            No
          </button>
        </div>
      </Modal>
      <Header />
      {loading ? (
        <>
          <main className="py-7 flex flex-col gap-5 items-center justify-center bg-[#ddd]">
            <img src={loadingImage} alt="Loading..." />
            <p className="text-center">Please wait, fetching data...</p>
          </main>
        </>
      ) : (
        <main className="bg-cart bg-cover bg-center">
          <div className="global-px  space-y-3 py-10">
            <section className="text-white lg:text-3xl text-2xl font-extrabold drop-shadow-lg text-center md:text-left max-w-sm">
              Finish customer order now.
            </section>
            <section className="flex flex-col md:flex-row lg:gap-16 gap-10">
              <aside className="flex-1 flex">
                <section className="flex bg-white rounded-lg p-2 lg:p-7 flex-col w-full relative">
                  <button
                    disabled={focusedCard === 0}
                    className="carousel-control absolute bg-tertiary text-white flex items-center justify-center rounded-full w-8 h-8 top-4 left-4 hover:bg-primary-focus disabled:btn-disabled"
                    onClick={scrollLeft}
                  >
                    &lt;
                  </button>
                  <button
                    disabled={focusedCard === order.length - 1}
                    className="carousel-control absolute bg-tertiary text-white flex items-center justify-center rounded-full w-8 h-8 top-4 right-4 hover:bg-primary-focus disabled:btn-disabled"
                    onClick={scrollRight}
                  >
                    &gt;
                  </button>
                  <div
                    className=" carousel rounded-box max-w-md lg:max-w-lg"
                    //   onScroll={handleScroll}
                    ref={carouselRef}
                  >
                    {order.map((item, key) => (

                      <div className="carousel-item w-full" key={key}>
                        <section className="flex flex-col items-center w-full py-4 p-3">
                          <div className="w-full">
                            <p className="text-tertiary font-bold text-center text-xl  md:text-2xl">
                              Verify Order
                            </p>
                            {/*
                            <p className="text-center">
                              for{" "}
                              {item.receiver_name ||
                                getEmailUsername(item.receiver_email)}
                            </p>
                            */}
                          </div>
                          <section className="flex flex-col h-96 overflow-y-scroll w-full  gap-4 my-4">
                            {item.orders.map((product, key) => (
                              
                              <div
                                className="flex flex-row gap-2 lg:gap-5 w-full lg:text-lg items-center relative "
                                key={key}
                              >
                                <aside className="flex-1">
                                  <div className="avatar">
                                    <div className="w-20 md:w-28 rounded md:rounded-lg">
                                      <img
                                        src={
                                          product.img ||
                                          productPlaceholder
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <img
                                  src={productPlaceholder}
                                  alt="name"
                                  className="aspect-square object-cover rounded-xl"
                                /> */}
                                </aside>
                                <aside className="flex-[2_2_0%]">
                                  <p className="font-semibold">
                                    {product.name.split(" - ")[0] || product.name}
                                  </p>
                                  <div className="flex gap-2">
                                    <p>x {product.qty}</p>
                                  </div>
                                  <p>{product.name.split(" - ")[1] || "Regular"}</p>
                                </aside>
                                <aside className="flex-1">
                                  <p className="text-right">
                                     {inr_f(product.subtotal)}
                                    {/* IDR {n_f(Number(list.price) * Number(list.qty))} */}
                                  </p>
                                </aside>
                              </div>
                            ))}
                          </section>
                          <div className="border-b w-full"></div>

                          <section className="flex flex-col w-full my-4">
                            <div className="flex flex-row uppercase lg:text-lg">
                              <p className="flex-[2_2_0%]">Subtotal</p>
                              <p className="flex-1 lg:flex-none text-right">
                                {inr_f(item.total)}
                              </p>
                            </div>
                            <div className="flex flex-row uppercase lg:text-lg">
                              <p className="flex-[2_2_0%]">Discount</p>
                              <p className="flex-1 lg:flex-none text-right">
                              {inr_f(item.discount)}
                              </p>
                            </div>
                            
                            <div className="flex flex-row uppercase  lg:text-xl font-bold my-10">
                              <p className="flex-[2_2_0%]">Total</p>
                              <p className="flex-initial lg:flex-none">
                                 {inr_f(item.grand_total)}
                              </p>
                            </div>
                          </section>
                        </section>
                      </div>
                    ))}
                  </div>
                </section>
              </aside>
              <aside className="flex-1 flex flex-col gap-5">
                <section className="text-white text-xl lg:text-2xl font-extrabold drop-shadow-lg text-center md:text-left relative items-center">
                  Address details
                  <button
                    //   onClick={editMode ? saveEditInfo : toggleEdit}
                    className="absolute text-lg right-0 bottom-0 top-1 hover:underline"
                  >
                    {/* {editMode ? "save" : "edit"} */}
                  </button>
                </section>
                <section className="bg-white rounded-xl  p-5 lg:p-7 space-y-2">
                  <div className="flex gap-1">
                    <p>
                      <b>Delivery</b> to{" "}
                      {order[focusedCard]?.delivery_address ||
                        "Chaiguru counter"}
                    </p>
                  </div>
                  <hr />

                  <p>{order[focusedCard]?.notes || "no notes"}</p>
                  <hr />
                  <p>{order[focusedCard]?.phone_number || ""}</p>

                  <p>
                  <b>Payment method:</b> {" "}
                    {order[focusedCard]?.payment_method || "Cash"}
                  </p>
                  <p>
                    <b>Order time:</b> {" "}
                    {timespan_date(order[focusedCard]?.created_date) || ""}
                  </p>
                  <p>
                    <b>Created by:</b> {" "}
                    {order[focusedCard]?.createdBy || ""}
                  </p>
                  
                </section>
                
                <button
                  disabled={disabled || order[focusedCard]?.order_status != 1}
                  onClick={doneHandler}
                  className={`
                ${doneLoading && "loading" } 
                btn btn-block btn-primary text-white py-4 font-bold rounded-lg disabled:bg-opacity-100`}
                >
                  Mark as Done
                </button>
              </aside>
            </section>
          </div>
        </main>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrder);
